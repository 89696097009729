import * as API from "@api";
import config from "@config";
import { message } from "antd";

export default class CustomArticleBlock {
  constructor({ data, api }) {
    this.api = api;
    this.data = data;

    this.articleId = this.data.articleId || "";
    this.articleData = this.data.articleData || {};
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("embed-article");
    this.input = document.createElement("input");
    this.input.classList.add("input");
    this.input.type = "text";
    this.input.placeholder =
      "Введите ID материала или полную ссылку на него (напр. https://kapital.kz/finance/...)";
    this.input.value = this.articleId;
    this.button = document.createElement("button");
    this.button.classList.add("search-btn");
    this.button.innerHTML = "Найти материал";
    this.button.addEventListener("click", this.searchArticle.bind(this));

    this.articleWrapper = document.createElement("div");
    this.articleWrapper.classList.add("article-wrapper");
    this.articleWrapper.classList.add("hidden");

    this.articleImg = document.createElement("div");
    this.articleImg.classList.add("article-img");

    this.articleMeta = document.createElement("div");
    this.articleMeta.classList.add("article-meta");

    this.imageElement = document.createElement("img");
    this.imageElement.classList.add("article-image");
    this.articleImg.appendChild(this.imageElement);

    this.categoryElement = document.createElement("div");
    this.categoryElement.classList.add("article-category");
    this.articleMeta.appendChild(this.categoryElement);

    this.nameElement = document.createElement("div");
    this.nameElement.classList.add("article-name");
    this.articleMeta.appendChild(this.nameElement);

    this.articleWrapper.appendChild(this.articleImg);
    this.articleWrapper.appendChild(this.articleMeta);
    this.articleWrapper.appendChild(this.articleMeta);

    this.wrapper.appendChild(this.input);
    this.wrapper.appendChild(this.button);
    this.wrapper.appendChild(this.articleWrapper);

    this.renderInitial();
  }

  /**
   * Method called on block creation or when loading saved data
   */
  render() {
    return this.wrapper;
  }

  /**
   * Method called when the block is focused
   */
  focus() {
    this.input.focus();
  }

  /**
   * Method called on block removal
   */
  save() {
    return {
      articleId: this.articleId,
      articleData: this.articleData,
    };
  }

  static get toolbox() {
    return {
      title: "Влож. материал",
      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke-width="0" d="M19.0875 3.89999H4.9125C3.8577 3.89999 3 4.70729 3 5.69999V18.3C3 19.2927 3.8577 20.1 4.9125 20.1H19.0875C20.1423 20.1 21 19.2927 21 18.3V5.69999C21 4.70729 20.1423 3.89999 19.0875 3.89999ZM19.0875 18.3H4.9125C4.8612 18.3 4.8261 18.2856 4.8108 18.2856C4.8045 18.2856 4.8009 18.2874 4.8 18.2928L4.7892 5.74139C4.7955 5.73239 4.836 5.69999 4.9125 5.69999H19.0875C19.1586 5.70089 19.1973 5.72519 19.2 5.70719L19.2108 18.2586C19.2045 18.2676 19.164 18.3 19.0875 18.3Z" fill="black"/>
      <path stroke-width="0" d="M6.6001 7.5H12.0001V12.9H6.6001V7.5ZM12.9001 14.7H6.6001V16.5H17.4001V14.7H13.8001H12.9001ZM13.8001 11.1H17.4001V12.9H13.8001V11.1ZM13.8001 7.5H17.4001V9.3H13.8001V7.5Z" fill="black"/>
      </svg>
      `,
    };
  }

  renderInitial() {
    if (this.articleId) {
      this.searchArticle({ preventDefault: () => {} });
    }
  }

  /**
   * Search for an article using the API
   */
  async searchArticle(e) {
    e.preventDefault();
    let articleId = this.input.value.trim();

    if (articleId === "") {
      // No article ID entered
      return;
    }

    // if articleId starts with https:// or http:// e.g. https://kapital.kz/finance/135350/kaspikz-zajmet-na-aix-i-lse-do-dollar750-mln.html, get id from url
    if (articleId.startsWith("https://") || articleId.startsWith("http://")) {
      const urlParts = articleId.split("/");

      if (urlParts?.[3] === "green") {
        articleId = urlParts[5];
      } else {
        articleId = urlParts[4];
      }
    }

    try {
      // Call your API to get the article data
      const articleData = await API.Articles.getOne({
        id: articleId,
      }).then((res) => res?.data);

      if (!articleData || articleData?.status !== "published") {
        return message.error(
          "Материал не найден или неопубликован, проверьте ID или ссылку",
          2
        );
      }

      delete articleData?.content;
      delete articleData?.blocks;
      delete articleData?.last_modified_user_id;
      delete articleData?.tts;
      delete articleData?.created_at;

      this.articleData = articleData;

      this.articleId = articleData.id;

      this.input.classList.add("hidden");
      this.button.classList.add("hidden");
      this.articleWrapper.classList.remove("hidden");

      // Update the DOM with article information
      this.nameElement.textContent = articleData.title;
      this.imageElement.src = articleData.image
        ? `${config.staticApi}/img?file=${articleData.image}`
        : "/img/no-img.svg";
      this.categoryElement.innerHTML = `<i>ID ${articleData.id}</i> ${
        articleData?.category?.name || "Без рубрики"
      }`;
    } catch (error) {
      message.error("Вложенный материал не найден", 2);
    }
  }
}
