import { Form, Input } from "antd";
import { LinkOutlined } from "@ant-design/icons";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="redirect_to"
      className="w-full"
      label="301-редирект"
    >
      <Input
        prefix={<LinkOutlined />}
        controls={false}
        minLength={3}
        className="w-full"
        placeholder='Введите относительный URL, начинающийся с "/" (напр. /info/almaty)'
      />
    </Form.Item>
  );
}
