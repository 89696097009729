import { Card, Divider, Form, Switch, Tooltip } from "antd";
import ArticleSearch from "./article";

export default function Meta(props) {
  return (
    <Card title="Дополнительно" bordered={false}>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between items-center w-full hover:text-neutral-500">
          <span className="block leading-none cursor-help">
            <Tooltip title="Материал будет размещен в сайдбаре (слева)">
              Важная новость (в сайдбар)
            </Tooltip>
          </span>
          <Form.Item {...props} name="is_important" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className="flex justify-between items-center w-full hover:text-neutral-500">
          <span className="block leading-none cursor-help">
            <Tooltip title="Материал будет размещен в главном блоке ТОП-5">
              В слайдер на главную
            </Tooltip>
          </span>
          <Form.Item {...props} name="in_slider" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className="flex justify-between items-center w-full hover:text-neutral-500">
          <span className="block leading-none cursor-help">
            <Tooltip title="Материал будет с пометкой «Срочно»">
              Срочная новость
            </Tooltip>
          </span>
          <Form.Item {...props} name="is_breaking" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className="flex justify-between items-center w-full hover:text-neutral-500">
          <span className="block leading-none cursor-help">
            <Tooltip title="Материал будет иметь пометку PR">
              Пометка «PR»
            </Tooltip>
          </span>
          <Form.Item {...props} name="is_pr" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className="flex justify-between items-center w-full hover:text-neutral-500">
          <span className="block leading-none cursor-help">
            <Tooltip title="Материал дополнительно будет показываться в блоке «Выбор редакции»">
              Выбор редакции
            </Tooltip>
          </span>
          <Form.Item {...props} name="is_editor_choice" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className="flex justify-between items-center w-full hover:text-neutral-500">
          <span className="block leading-none cursor-help">
            <Tooltip title="Материал будет иметь пометку «LIVE»">LIVE</Tooltip>
          </span>
          <Form.Item {...props} name="is_live" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className="flex justify-between items-center w-full hover:text-neutral-500">
          <span className="block leading-none cursor-help">
            <Tooltip title="Вечнозеленый материал, будет размещен в папке /green">
              Материал GREEN
            </Tooltip>
          </span>
          <Form.Item {...props} name="is_green" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className="flex justify-between items-center w-full hover:text-neutral-500">
          <span className="block leading-none cursor-help">
            <Tooltip title="Пользователи смогут оставлять комментарии">
              Разрешить комментарии
            </Tooltip>
          </span>
          <Form.Item {...props} name="is_commentable" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
      </div>
      <Divider>Версия на другом языке</Divider>
      <span className="text-sm block text-center w-full text-neutral-400 mb-5">
        К этому материалу можно подвязать материал на другом языке. На сайте обе
        версии будут ссылаться друг на друга с возможностью переключения языка.
      </span>
      <ArticleSearch {...props} />
    </Card>
  );
}
