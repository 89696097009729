import {
  Dropdown,
  Card,
  Tooltip,
  Avatar,
  Divider,
  Alert,
  Button,
  InputNumber,
  message,
} from "antd";
import {
  SaveOutlined,
  SendOutlined,
  ClockCircleOutlined,
  HighlightOutlined,
  StopFilled,
  CalendarOutlined,
  EditOutlined,
  UserOutlined,
  GlobalOutlined,
  EyeOutlined,
  EyeFilled,
  EditFilled,
  CheckOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import config from "@config";
import Planned from "./planned";
import Publish from "./publish";
import * as API from "@api/articles";
import { useState } from "react";
import { useAuth } from "@contexts";

export default function Actions(params) {
  const { currentUser } = useAuth();
  const [next, setNext] = useState(null);
  const [views, setViews] = useState(params?.data?.views || 0);
  const [showViewsUpdateModal, setShowViewsUpdateModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleActionsClick = ({ key }) => {
    switch (key) {
      case "publish":
      case "schedule":
        return setNext(key);

      case "unpublished":
      case "cancel_publish":
      case "in_review":
      case "draft":
        params.form.setFieldsValue({ next_status: key });
        return params.form.submit();

      case "open_url":
        const id = params?.data?.id;
        const slug = params?.data?.slug;
        const category = params?.data?.category?.slug;

        let url = `${config.frontend}/${category}/${id}/${slug}.html`;

        if (params?.data?.is_green) {
          url = `${config.frontend}/green${url}`;
        }

        return window.open(url, "_blank");

      case "open_preview":
        const hashedId = params?.data?.meta?.hashed_preview_id || null;
        return window.open(
          `${config.frontend}/preview/${params?.data?.id}/${hashedId}`,
          "_blank"
        );

      default:
        break;
    }
  };

  return (
    <Card
      title="Публикация"
      bordered={false}
      extra={
        <Tooltip title="Последний, кто взаимодействовал с этим материалом.">
          <div className="flex justify-start items-center">
            <div className="text-right">
              <span className="block text-sm font-normal text-black leading-none">
                {params?.data?.user?.name || currentUser?.name || "N/a"}
              </span>
              <span className="block text-xs font-normal text-neutral-400 leading-none">
                Посл. редактирование
              </span>
            </div>
            <Avatar
              className="bg-neutral-200 ml-2"
              size="small"
              icon={<UserOutlined className="text-neutral-400" />}
            />
          </div>
        </Tooltip>
      }
    >
      {params?.data?.status === "planned" && (
        <Alert
          type="warning"
          showIcon
          description={`Этот материал запланирован к публикации в ${dayjs(
            params?.data?.planned_at
          ).format(
            "HH:mm DD MMM YYYY"
          )}. Вы все еще можете внести изменения в материал.`}
          message="Запланирован"
          className="mb-6"
        />
      )}
      {params?.data?.status === "published" && (
        <Alert
          type="success"
          showIcon
          description="Материал опубликован на сайте и доступен к просмотру."
          message="Опубликован"
          className="mb-6"
        />
      )}
      {params?.data?.status === "unpublished" && (
        <Alert
          type="error"
          showIcon
          description="Материал снят с сайта и не доступен к просмотру."
          message="Снят с публикации"
          className="mb-6"
        />
      )}

      <Dropdown.Button
        type="primary"
        className="m-0 full-width-dropdown"
        block
        loading={params.loading}
        disabled={params.loading}
        menu={{
          items: [
            {
              label: "Опубликовать сейчас",
              key: "publish",
              icon: <SendOutlined />,
              className:
                params.data.status !== "published" &&
                params.data.status !== "planned" &&
                "bg-blue-100 hover:bg-blue-500 text-blue-900 hover:text-white",
              disabled:
                params.data.status === "published" ||
                params.data.status === "planned",
            },
            {
              type: "divider",
            },
            {
              label: "Запланировать публикацию",
              key: "schedule",
              disabled:
                params.data.status === "published" ||
                params.data.status === "planned",
              icon: <ClockCircleOutlined />,
            },
            {
              label: "Отправить на проверку",
              key: "in_review",
              icon: <HighlightOutlined />,
              disabled: params.data.status === "published",
            },
            {
              type: "divider",
            },
            {
              label: "Открыть на сайте",
              key: "open_url",
              icon: <GlobalOutlined />,
              disabled: params.data.status !== "published",
            },
            {
              label: "Открыть превью",
              key: "open_preview",
              icon: <EyeOutlined />,
              disabled: params.data.status !== "in_review",
            },
            {
              type: "divider",
            },
            {
              label: "Отменить публикацию",
              key: "cancel_publish",
              danger: true,
              icon: <StopFilled />,
              hidden: params.data.status !== "planned",
            },
            {
              label:
                params.data.status === "in_review"
                  ? "Снять с проверки"
                  : "Снять с публикации",
              key: "unpublished",
              danger: true,
              icon: <StopFilled />,
              hidden: params.data.status === "planned",
              disabled:
                params.data.status !== "published" &&
                params.data.status !== "in_review",
            },
          ],
          onClick: handleActionsClick,
        }}
        onClick={params.form.submit}
      >
        <SaveOutlined /> Сохранить
      </Dropdown.Button>

      {params.data.status === "in_review" && (
        <Button
          onClick={() => handleActionsClick({ key: "open_preview" })}
          className="mt-3"
          block
          icon={<EyeOutlined />}
          loading={params.loading}
          disabled={params.loading}
        >
          Открыть превью
        </Button>
      )}

      {params.data.status === "in_review" && (
        <Button
          danger
          onClick={() => handleActionsClick({ key: "unpublished" })}
          className="mt-3"
          block
          loading={params.loading}
          disabled={params.loading}
        >
          Снять с проверки
        </Button>
      )}

      <Divider />

      <div className="w-full grid grid-cols-3 gap-2">
        <Tooltip title="Неуникальные просмотры">
          <div className="col-span-1 flex flex-col justify-center items-center bg-neutral-50 rounded-xl px-2 py-5 gap-3 relative">
            <Button
              shape="default"
              icon={
                showViewsUpdateModal ? (
                  <EditFilled className="text-primary" />
                ) : (
                  <EditOutlined />
                )
              }
              className="absolute right-2 top-2"
              type="text"
              onClick={() => {
                if (showViewsUpdateModal) {
                  if (!params.form.getFieldValue("updated_views")) {
                    setViews(params.data.views);
                  }
                }

                setShowViewsUpdateModal(!showViewsUpdateModal);
              }}
            />
            <EyeFilled className="text-neutral-400 text-xl" />
            {showViewsUpdateModal ? (
              <Button.Group>
                <InputNumber
                  className="w-full rounded-r-none"
                  size="small"
                  min={0}
                  controls={false}
                  value={views}
                  defaultValue={params?.data?.views || 0}
                  onChange={(value) => setViews(value)}
                  placeholder="Введите количество просмотров"
                />
                <Button
                  type="primary"
                  size="small"
                  className="px-1"
                  icon={<CheckOutlined />}
                  onClick={() => {
                    setShowViewsUpdateModal(false);
                    params.form.setFieldsValue({ updated_views: views });

                    messageApi.open({
                      type: "loading",
                      content: "Обновление просмотров...",
                      duration: 0,
                    });

                    return API.updateViews({
                      id: params?.data?.id,
                      views,
                    })
                      .then((res) => {
                        messageApi.destroy();

                        if (res?.error) {
                          return message.error(
                            "Ошибка обновления просмотров",
                            2
                          );
                        }

                        return message.success(
                          "Просмотры успешно обновлены",
                          2
                        );
                      })
                      .catch((err) => {
                        messageApi.destroy();
                        return message.error("Ошибка обновления просмотров", 2);
                      });
                  }}
                />
              </Button.Group>
            ) : (
              <span className="text-[12px] font-medium text-black text-center block w-full m-0 leading-none">
                {views || "---"}{" "}
              </span>
            )}
          </div>
        </Tooltip>
        <Tooltip title="Дата публикации">
          <div className="col-span-1 flex flex-col justify-center items-center bg-neutral-50 rounded-xl px-1 py-5 gap-3">
            <CalendarOutlined className="text-neutral-400 text-xl" />
            <span className="text-[12px] font-medium text-black text-center block w-full m-0 leading-none">
              {params?.data?.published_at
                ? dayjs(params.data.published_at).format("HH:mm DD.MM.YYYY")
                : "---"}
            </span>
          </div>
        </Tooltip>
        <Tooltip title="Дата посл. изменения">
          <div className="col-span-1 flex flex-col justify-center items-center bg-neutral-50 rounded-xl px-1 py-5 gap-3">
            <EditOutlined className="text-neutral-400 text-xl" />
            <span className="text-[12px] font-medium text-black text-center block w-full m-0 leading-none">
              {dayjs(params?.data?.updated_at).format("HH:mm DD.MM.YYYY")}
            </span>
          </div>
        </Tooltip>
      </div>

      <Planned
        form={params.form}
        onCancel={() => setNext(null)}
        open={next === "schedule"}
      />
      <Publish
        form={params.form}
        onCancel={() => setNext(null)}
        open={next === "publish"}
      />
    </Card>
  );
}
