import { Button, Modal, Tooltip, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import * as API from "@api/articles";

export default function LinksButton({ article }) {
  const handleDelete = () =>
    Modal.confirm({
      width: 500,
      title:
        article?.status === "published"
          ? `Вы уверены, что хотите удалить материал «${article.title}»?`
          : "Удалить материал?",
      content:
        article?.status === "published"
          ? `Удаление опубликованных материалов может повлиять на SEO-позиции сайта, т.к. раннее страница материала могла быть проиндексирована поисковыми системами.`
          : `Вы уверены, что хотите удалить «${article.title}»?`,
      onOk: async () => {
        if (article?.status === "published") {
          message.loading("Снимаем материал с сайта...", 1);
          const unpublished = await API.unpublish({ id: article.id }).catch(
            (err) => {
              console.error(err);
              return { error: true };
            }
          );

          if (unpublished?.error || unpublished?.data?.error) {
            return message.error(
              "Не удалось удалить материал, попробуйте снова или обратитесь к администратору",
              2
            );
          }
        }

        return API.destroy({ id: article.id })
          .then(() => {
            message.success("Материал успешно удален", 1, () =>
              window.location.reload()
            );
          })
          .catch((err) => {
            console.error(err);
            message.error("Не удалось удалить материал");
          });
      },
      centered: true,
      okButtonProps: { danger: true },
      okText:
        article?.status === "published"
          ? "Понимаю риски, удалить"
          : "Да, удалить",
    });

  return (
    <Tooltip title="Удалить материал" placement="top">
      <Button
        type="dashed"
        danger
        icon={<DeleteOutlined />}
        onClick={handleDelete}
      />
    </Tooltip>
  );
}
