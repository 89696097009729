import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function ArticleFilter({ onSearch, ...props }) {
  return (
    <Input.Search
      placeholder="Поиск по заголовку или ID"
      allowClear
      onSearch={onSearch}
      enterButton="Найти"
      prefix={<SearchOutlined />}
      {...props}
    />
  );
}
