import { useEffect, useState } from "react";
import { Image as AntImage, message, Button, Skeleton } from "antd";
import { LoadingOutlined, PictureOutlined } from "@ant-design/icons";
import { useDropzone } from "react-dropzone";
import { Imgproxy } from "@utils";
import config from "@config";
import * as API from "@api";

function SkeletonWrapper(props) {
  return (
    <div className="w-full bg-white border-neutral-200 border-solid border rounded-md overflow-hidden flex flex-row flex-nowrap justify-between items-start">
      <div className="w-full max-w-[180px] overflow-hidden">
        <div className="aspect-[200/133] w-full bg-neutral-100 object-center object-cover">
          {props.children}
        </div>
        {props?.deleteButton}
      </div>
      <div className="w-full p-3">
        <Skeleton paragraph={false} className="w-[40%] mb-2" />
        <Skeleton paragraph={false} className="w-full" />
        <Skeleton paragraph={false} className="w-[70%]" />
      </div>
    </div>
  );
}

export default function Photos({ form }) {
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(
    form.getFieldValue("preview_thumbnail") || null
  );

  const { getRootProps, getInputProps } = useDropzone({
    disabled: loading || photo,
    multiple: false,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpeg", ".jpg"],
      // webp
      "image/webp": [".webp"],
    },
    onDrop: async (file) => {
      if (Array.isArray(file)) {
        file = file[0];
      }

      if (file.size > 15 * 1000 * 1000) {
        return message.error(
          "Размер файла не должен превышать 5MB, пожалуйста, выберите другой файл."
        );
      }

      try {
        setLoading(true);
        const _res = await API.Static.uploadImage({
          file,
        });

        if (!_res || !_res?.data?.path) {
          throw new Error();
        }

        setLoading(false);
        setPhoto(_res?.data?.path);
        return message.success("Изображение загружено");
      } catch (error) {
        setLoading(false);
        return message.error(
          `Ошибка при загрузке изображения: ${error.message}`,
          2
        );
      }
    },
  });

  useEffect(() => {
    form.setFieldsValue({ preview_thumbnail: photo });
  }, [photo]);

  const onImageDelete = (idx) => {
    setPhoto(null);
  };

  return (
    <div>
      {photo ? (
        <SkeletonWrapper
          deleteButton={
            <div className="p-2">
              <Button onClick={onImageDelete} block danger type="text">
                Удалить фото
              </Button>
            </div>
          }
        >
          <img
            className="w-full aspect-[200/133] object-center object-cover"
            src={Imgproxy.getImage(photo)}
            alt="Превью"
          />
        </SkeletonWrapper>
      ) : (
        <SkeletonWrapper>
          <div
            className="ant-upload ant-upload-drag cursor-pointer hover:border-primary transition-long hover:transition-long bg-neutral-50 border-dashed border border-neutral-300 p-2"
            {...getRootProps()}
          >
            <input
              multiple={false}
              {...getInputProps()}
              accept=".png, .jpg, .jpeg, .webp"
            />
            <p className="mx-auto block w-fit text-4xl mb-3 text-primary">
              {loading ? <LoadingOutlined /> : <PictureOutlined />}
            </p>
            <p className="text-xs text-black w-full block text-center mb-1">
              Нажмите или перетащите, чтобы загрузить фото
            </p>
            <p className="text-xs text-neutral-500 w-full block text-center">
              Макс. размер файла: 15MB
            </p>
          </div>
        </SkeletonWrapper>
      )}
    </div>
  );
}
